<template>
    <div class="meal-kits-section" :class="{ 'empty-page': !allMealKitsWithIO.length }">
        <div class="meal-kits__wrapper" v-if="allMealKitsWithIO.length">
            <h2>{{ $t('mealKits.title') }}</h2>
            <!-- <MealKitRouteCard
                class="meal-card"
                :name="'Kūčių stalo rinkinys'"
                :price="99"
                :isIndividual="true"
                @click="openCristmasForm()"
                :image-height="provideApp.$screenInnerWidth >= 1024 ? 424 : 133"
            /> -->
            <MealKitCard
                class="meal-card"
                @click="openMealKitPage(mealKit)"
                @open-cart="openCart(mealKit)"
                v-for="mealKit in allMealKitsWithIO"
                :key="mealKit.id"
                :id="mealKit.id"
                :name="$filters.getTranslationByKey(mealKit.name)"
                :price="mealKit?.price"
                :meals-amount="mealKit?.mealsAmount"
                :is-vegetarian="mealKit?.isVegetarian"
                :discount-type="mealKit.discount?.type"
                :discount-amount="mealKit.discount?.amount"
                :is-hidden="mealKit.isHidden"
                :image="mealKit.image"
                :is-individual="mealKit.isIndividual"
                :is-unique="mealKit?.isUnique"
                :is-added-to-cart="isAddedToCart(mealKit.id)"
                :image-height="provideApp.$screenInnerWidth >= 1024 ? 424 : 133"
            />
        </div>
        <h2 class="text-center" v-else>{{ $t('mealKits.thereAreNoMealKits') }}</h2>
    </div>
</template>

<script>
    import MealKitCard from '@/components/MealKits/MealKitCard.vue';
    // import MealKitRouteCard from '@/components/MealKits/MealKitRouteCard.vue';
    import { cartMixin, openMealKitPageMixin } from '@/utils/mixins';

    export default {
        name: 'AllMealKits',
        components: { MealKitCard },
        inject: ['provideApp'],
        mixins: [cartMixin, openMealKitPageMixin],
        methods: {
            openCristmasForm() {
                this.$router.push({
                    name: 'CristmasEveForm',
                })
            }
        }
    };
</script>

<style lang="scss" scoped>
    .meal-kits-section {
        padding-top: 25px;
        padding-bottom: 25px;

        h2 {
            width: 100%;
            margin-bottom: 20px;
        }

        .meal-card {
            margin-bottom: 10px;
        }
    }

    @include media($md) {
        .meal-kits__wrapper {
            @include row-align-stretch;
            flex-wrap: wrap;

            .meal-card {
                width: 49%;

                margin-right: 2%;

                &:nth-of-type(2n) {
                    margin-right: 0;
                }
            }
        }
    }

    @include media($lg) {
        .meal-kits-section {
            padding-top: 50px;
            padding-bottom: 50px;

            h2 {
                font-size: $font-34;
                margin-bottom: 25px;
            }

            .meal-card {
                width: 32%;
                margin-bottom: 20px;

                &:nth-of-type(2n) {
                    margin-right: 2%;
                }

                &:nth-of-type(3n) {
                    margin-right: 0;
                }
            }
        }
    }

    @include media($xl) {
        .meal-kits-section {
            .meal-card {
                width: 23.5%;
                margin-right: 2%;
                margin-bottom: 20px;

                &:nth-of-type(2n) {
                    margin-right: 2%;
                }

                &:nth-of-type(3n) {
                    margin-right: 2%;
                }

                &:nth-of-type(4n) {
                    margin-right: 0;
                }
            }
        }
    }
</style>
